import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

// import './assets/style/reset.css'

import 'view-design/dist/styles/iview.css'; //导入样式
import {
  Button,
  Table,
  Icon,
  Carousel,
  CarouselItem,
} from 'view-design';

Vue.use(ElementUI);
Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.component('Icon', Icon);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.prototype.$axios = axios;




import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'MBssT58lSDNv35Ioo56TnPHYPtGpLv4o'
})


import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import animated from 'animate.css'
Vue.use(animated)

import VueAwesomeSwiper from 'vue-awesome-swiper'



Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')