import axios from 'axios'
import config from '../config/index'

//修改时间长度10分钟
axios.defaults.timeout = 600000
axios.defaults.baseURL = config.apiRoot
axios.defaults.headers.maxHeaderSize = 1024000

// http request 拦截器
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response.data)})
        .catch(err => {
          let error=''
          if (err.message.indexOf('timeout') !== -1) {
            error='请求超时'
            reject(error)
          } else if (err.message.indexOf('Network') !== -1){
            error='网络连接异常'
            reject(error)
          } else{
            reject(err)
          }
        })
  })
}

