<template>
  <div>
    <div class="foot">
      <div class="left">
        <div class="title">CONTACT</div>
        <div class="contact">
          <!-- <div class="text">公司：{{datalist.companyName}}</div> -->
          <!-- <div class="text">电话：{{datalist.phone}}</div>
          <div class="text">邮箱：{{datalist.email}}</div>
          <div class="text">地址：{{datalist.address}}</div> -->
          <div class="text">公司:浙江骐骥软件科技有限公司</div>
          <div class="text">电话: 18006607370</div>
          <div class="text">邮箱: ykzhou@qi-soft.com</div>
          <div class="text">
             地址：浙江省嘉兴市经济技术开发区昌盛南路36号11幢404室
          </div>
        </div>
      </div>
      <div class="cen">
        <div class="title">快捷方式</div>
        <div class="product">
          <!-- <div class="item" v-for="item in product" :key="item.index">-->
          <!-- <a :href="item.link">  {{ item.productName }}</a> </div> -->
          <!-- <a style="color: #999" href="../views/introduction/introduction.vue"> 公司介绍</a> -->
       
            <div
              class="item"
              v-for="(item, index) in navList"
              :class="{ 'router-link-active': index === curIndex }"
              :key="item.index"
              @click="nav(index, item.path)"
              router-link
            >
              {{ item.name }}
            </div>
        
        </div>

        <p class="beian" style="color: #999">
          <a style="color: #999" href="https://beian.miit.gov.cn"
            >Copyright 2016@浙江骐骥软件科技有限公司 All Rights Reserved
            浙ICP备20012804号-1 | 隐私政策</a
          >
        </p>
        <div class="copyright">
          {{ datalist.icpNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "../utils/http.js";
import config from "../config/index";
export default {
  components: {},
  props: {},
  data() {
    return {
      datalist: {},
      product: [],
      navList: [
        { name: "首页", path: "/index" },
        { name: "公司介绍", path: "/introduction" },
        { name: "服务团队", path: "/team" },
        { name: "方案与案例", path: "/business" },
        { name: "社会招聘", path: "/recruit" },
        { name: "联系我们", path: "/contact" },
      ],
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.lode();
  },
  created() {},
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj",
      };
      let url = "/login";
      post(url, data).then((res) => {
        localStorage.setItem("token", res.token);
        this.ceshi();
        console.log(config.imageUrl);
      });
    },
    ceshi() {
      let url = "/portal/company/6";
      get(url, null).then((res) => {
        this.datalist = res.data.company;
        this.datalist.img = config.imageUrl + this.datalist.img;
      });
      let urls = "/portal/product/queryByCompanyId?companyId=6";
      get(urls, null).then((res) => {
        console.log(res);
        this.product = res.rows.slice(0, 6);
      });
    },
    nav(i, url) {
      this.curIndex = i;
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang="less" scoped>

.foot {
  height: 210px;
  background: #373f5e;
  color: #fff;
  display: flex;
  justify-content: space-around;
  .title {
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 28px;
  }
  .left {
    width: 220px;
    height: 210px;
    .contact {
      margin-top: 28px;
      .text {
        min-width: 300px;
        color: #8e93a4;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .cen {
    width: 620px;
    height: 210px;
    .beian {
      margin-top: 10px;
      text-align: center;
      a {
        color: #fff;
      }
    }
    .product {
      margin-top: 25px;
      height: 38px;
      width: 620px;
      border-bottom: 1px solid #8e93a4;
      display: flex;
      justify-content: space-around;
      .item {
        // background: yellow;
        height: 28px;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #000014;
        }
      }
    }
    .copyright {
      min-width: 600px;
      margin-top: 28px;
      font-size: 14px;
      color: #8e93a4;
      text-align: center;
    }
  }
  .right {
    width: 120px;
    height: 210px;
    text-align: center;
    .img {
      margin-top: 12px;
      width: 116px;
      height: 116px;
      border-radius: 6px;
    }
  }
}
</style>
